<template>
  <div>
    <HomePageTop/>
    <div class="main">
      <div class="form">
        <el-form ref="formRef" :rules="rules" :model="form" label-width="270px">
          <el-form-item :label="$fanyi('当前账户余额') + '：'">
            <div class="flexAndCenter balance">
              <div>{{ $fun.yenNumSegmentation(form.client_balance) }}</div>
              <div>{{ $fanyi('日元') }}</div>
            </div>
          </el-form-item>
          <el-form-item :label="$fanyi('出金金額') + '：'" prop="client_amount">
            <el-input v-model="form.client_amount" @input="extractAmountVerify" @keydown.native="channelInputLimit"/>
          </el-form-item>
          <el-form-item :label="$fanyi('銀行名') + '：'" prop="client_bank_name">
            <el-input v-model="form.client_bank_name"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('支店番号') + '：'" prop="client_bank_branch_no">
            <el-input v-model="form.client_bank_branch_no"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('支店名') + '：'" prop="client_bank_branch_name">
            <el-input v-model="form.client_bank_branch_name"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('預金種目') + '：'" prop="client_pay_type">
            <el-input v-model="form.client_pay_type"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('收款户号') + '：'" prop="client_account">
            <el-input v-model="form.client_account"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('口座名義') + '：'" prop="client_bank_username">
            <el-input v-model="form.client_bank_username"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('提现申请原因') + '：'" prop="client_reason">
            <el-input type="textarea" resize="none" v-model="form.client_reason"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn">
              <button class="submit" @click.prevent="confirmSubmit">
                {{ $fanyi("确认提交") }}
              </button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
        :visible.sync="withdrawDepositErrorDialogVisible"
        width="372px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
      <div class="dialogHeader flexAndCenter">
        <div>!</div>
        <div>{{ $fanyi("提示") }}</div>
      </div>
      <div class="dialogFooter flexAndCenter">
        <div>{{ $fanyi("提现申请发起失败，余额不足，无法发起提现") }}</div>
        <div @click="withdrawDepositErrorDialogVisible=false">{{ $fanyi("确定") }}</div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="withdrawDepositSuccessDialogVisible"
        width="372px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
      <div class="dialogHeader flexAndCenter">
        <div>!</div>
        <div>{{ $fanyi("提示") }}</div>
      </div>
      <div class="dialogFooter flexAndCenter">
        <div>{{ $fanyi("提现申请发起成功") }}</div>
        <div class="color333 fontWeightBold">{{ $fanyi("最晚1-2个工作日资金自动打入账户") }}</div>
        <div @click="withdrawDepositSuccessDialogVisible=false;$router.go(-1);">{{ $fanyi("确定") }}</div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="withdrawDepositMessageConfirmDialogVisible"
        width="372px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
      <div class="dialogHeader flexAndCenter">
        <div>!</div>
        <div>{{ $fanyi("提示") }}</div>
      </div>
      <div class="dialogCenter">
        <div class="newTitle">{{ $fanyi("请您核对以下信息是否正确!") }}</div>
        <div class="confirmMessageContainer">
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("出金金額") }}：</div>
            <div class="flexAndCenter balance">
              <div class="overflowText" :title="form.client_amount" style="max-width:128px">{{
                  form.client_amount
                }}
              </div>
              <div>{{ $fanyi('日元') }}</div>
            </div>
          </div>
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("銀行名") }}：</div>
            <div class="overflowText" :title="form.client_bank_name">{{ form.client_bank_name }}</div>
          </div>
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("支店番号") }}：</div>
            <div class="overflowText" :title="form.client_bank_branch_no">{{ form.client_bank_branch_no }}</div>
          </div>
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("支店名") }}：</div>
            <div class="overflowText" :title="form.client_bank_branch_name">{{ form.client_bank_branch_name }}</div>
          </div>
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("預金種目") }}：</div>
            <div class="overflowText" :title="form.client_pay_type">{{ form.client_pay_type }}</div>
          </div>
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("收款户号") }}：</div>
            <div class="overflowText" :title="form.client_account">{{ form.client_account }}</div>
          </div>
          <div class="flexAndCenter">
            <div class="label">{{ $fanyi("口座名義") }}：</div>
            <div class="overflowText" :title="form.client_bank_username">{{ form.client_bank_username }}</div>
          </div>
        </div>
      </div>
      <div class="newDialogFooter flexAndCenter">
        <div @click="submit">{{ $fanyi("确定") }}</div>
        <div @click="withdrawDepositMessageConfirmDialogVisible=false">{{ $fanyi("取消") }}</div>
      </div>
    </el-dialog>
    <FootVue/>
  </div>
</template>
<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";
import Dialog from "../../components/public/Dialog";

export default {
  data() {
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      let text = value.trim()
      if (text === '') {
        callback(new Error(this.$fanyi('不能全由空格组成')))
      } else {
        callback()
      }
    }
    //提现金额不能大于余额
    var isLegal = (rule, value, callback) => {
      if (Number(this.form.client_balance) < Number(this.form.client_amount)) {
        callback(new Error(this.$fanyi("提现申请发起失败，余额不足，无法发起提现")));
      } else {
        callback();
      }
    };
    return {
      form: {
        client_balance: "",
        client_amount: "",
        client_reason: "",
        client_bank_name: "",
        client_bank_branch_name: '',
        client_pay_type: '',
        client_bank_username: ''
      },
      withdrawDepositSuccessDialogVisible: false,
      withdrawDepositErrorDialogVisible: false,
      withdrawDepositMessageConfirmDialogVisible: false,
      //   表单验证规则
      rules: {
        bank_account_name: [
          {
            required: true,
            message: this.$fanyi("请输入户名"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 18 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["blur", "change"],
          }
        ],
        bank_account_number: [
          {
            required: true,
            message: this.$fanyi("请输入银行账户"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 64 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["blur", "change"],
          }
        ],
        client_amount: [
          {
            required: true,
            message: this.$fanyi("请输入提现金额"),
            trigger: ["blur", "change"],
          },
          {
            validator: isLegal,
            trigger: ["blur", "change"],
          },
        ],
        client_bank_name: [
          {
            required: true,
            message: this.$fanyi("请输入银行名"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 64 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
        client_bank_branch_no: [
          {
            required: true,
            message: this.$fanyi("请输入支店番号"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 64 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
        client_bank_branch_name: [
          {
            required: true,
            message: this.$fanyi("请输入支店名"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 64 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
        client_pay_type: [
          {
            required: true,
            message: this.$fanyi("请输入預金種目"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 64 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
        client_bank_username: [
          {
            required: true,
            message: this.$fanyi("请输入口座名義"),
            trigger: "blur",
          },
          {min: 1, max: 64, message: this.$fanyi("长度在 1 到 64 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
        client_reason: [
          {
            required: true,
            message: this.$fanyi("请输入口座名義"),
            trigger: "blur",
          },
          {min: 1, max: 200, message: this.$fanyi("长度在 1 到 200 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
        client_account: [
          {
            required: true,
            message: this.$fanyi("请输入口座番号"),
            trigger: "blur",
          },
          {min: 1, max: 200, message: this.$fanyi("长度在 1 到 200 个字符"), trigger: 'blur'},
          {
            validator: FieldCalibration,
            trigger: ["change"],
          }
        ],
      },
    };
  },
  components: {HomePageTop, Dialog, FootVue},
  computed: {},
  mounted() {
    let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
    this.form.client_balance = userData.balance_availability;
  },
  methods: {
    submit() {
      // if (!this.form.client_invoice) {
      //   return this.$message.warning(this.$fanyi("必填参数缺失"));
      // }
      this.withdrawDepositMessageConfirmDialogVisible = false;
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.form.client_balance = undefined;
          this.$api.capitalCashOutSend(this.form).then((res) => {
            if (res.code != 0) {
              this.form.client_balance = this.$store.state.userInfo.balance;
              return this.$message.error(this.$fanyi("操作失败"));
            }
            this.$api.japanGetUserInfo().then((res) => {
              this.$store.commit("userData", res.data);
              this.withdrawDepositSuccessDialogVisible = true;
              this.form.client_balance = res.data.balance;
            });
          });
        }
      });
    },
    //触发提现信息确认弹窗
    confirmSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (Number(this.form.client_balance) < Number(this.form.client_amount)) {
            this.withdrawDepositErrorDialogVisible = true;
            return
          }
          this.withdrawDepositMessageConfirmDialogVisible = true;
        }
      });
    },
    //提现金额输入校验
    channelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "。" || key == "." || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //提现金额格式校验
    extractAmountVerify(val) {
      this.form.client_amount = this.$fun.priceReplace(val)
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../css/mixin.scss";

/deep/ .el-dialog__header, /deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialogHeader {
  padding-left: 26px;
  height: 46px;
  border-bottom: 1px solid #DFDFDF;

  div:first-child {
    width: 20px;
    height: 20px;
    background: #B4272B;
    border-radius: 50%;
    color: #fff;
    margin-right: 6px;
    line-height: 20px;
    text-align: center;
  }

  div:last-child {
    color: #333333;
    font-weight: bold;
  }
}

.dialogCenter {
  .newTitle {
    margin: 20px 0 30px 20px;
    font-size: 20px;
    color: #B4272B;
    font-weight: bold;
  }

  .confirmMessageContainer {
    width: 280px;
    margin: 0 auto 30px;

    .label {
      text-align: right;
      width: 100px;
    }

    .balance {
      div:first-child {
        color: #B4272B;
        font-weight: bold;
        font-size: 16px;
        margin-right: 10px;
      }
    }

    .overflowText {
      //width: 135px;
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.dialogFooter {
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 20px;

  div:first-child {
    font-weight: bold;
    color: #333333;
  }

  div:last-child {
    margin-top: 39px;
    width: 120px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
  }
}

.newDialogFooter {
  justify-content: center;
  padding-bottom: 20px;

  div {
    width: 120px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    text-align: center;
  }

  div:first-child {
    margin-right: 30px;
  }

  div:last-child {
    background: #F59A23;
  }
}

.main {
  background: #ffffff;
  margin-top: -56px;
  padding-top: 30px;
  margin-bottom: 30px;

  header {
    height: 73px;
    padding-left: 25px;
    color: #333333;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid #DFDFDF;
    line-height: 73px;
  }

  .balance {
    div:first-child {
      color: #B4272B;
      font-weight: bold;
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .form {
    width: 568px;
    margin: 59px auto 0;

    /deep/ .el-form-item {
      margin-bottom: 26px;
    }

    /deep/ .el-input__inner {
      width: 280px;
      height: 34px;
      color: black;
      background: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #DFDFDF;
    }

    /deep/ .el-textarea__inner {
      width: 280px;
      height: 100px;
      color: black;
      background: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #DFDFDF;
      //margin-top: 12px;
    }

    .title {
      font-size: 14px;
      color: #ffa724;
      line-height: 19px;
    }
  }

  .btn {
    margin-top: 26px;
    width: 100%;
    text-align: left;
    padding-bottom: 105px;

    .submit {
      line-height: 24px;
      color: #ffffff;
      width: 280px;
      height: 34px;
      background: #B4272B;
      border-radius: 3px;
      font-size: 14px;
    }
  }
}

/deep/ .el-dialog__body {
  padding-bottom: 0px !important;
}

/deep/ .el-form-item__error {
  margin-top: 2px;
  white-space: nowrap;
}

.notarizeBox {
  .tiShi {
    font-size: 14px;
    color: #888888;
    line-height: 19px;
    margin-bottom: 25px;
  }

  > p {
    margin-bottom: 19px;
    display: flex;
    align-items: center;

    label {
      width: 235px;
      display: block;
      text-align: right;
      height: 20px;
      font-size: 14px;

      color: #000000;
      line-height: 20px;
      margin-right: 10px;
    }

    span {
      height: 20px;
      font-size: 14px;

      color: #000000;
      line-height: 20px;
      display: flex;
      align-items: center;

      .price {
        color: #ffa724;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        margin-right: 10px;
      }
    }
  }
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  background-color: #f6f6f6;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 15px;

  .elseImg {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;

    /deep/ .el-image.pay_image {
      $imgSize: 120px;
      width: $imgSize;
      // height: $imgSize;
      transition: 0.3s;
      text-align: center;

      i {
        font-size: $imgSize/4;
        line-height: $imgSize;
      }
    }

    img {
      max-width: 120px;
    }
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.el-icon-folder-checked {
  font-size: 40px;
  color: #1120c1;
}
</style>
